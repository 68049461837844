<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    variant?: "black" | "blue" | "white" | "orange";
    withClaim?: boolean;
    width: number;
    height: number;
  }>(),
  {
    variant: "blue",
    withClaim: true,
  }
);

watchEffect(() => {
  if (props.variant !== "black" && props.variant !== "blue" && props.withClaim) {
    console.warn('The "withClaim" option is only valid when "variant" is set to "black" or "blue".');
  }
});

const imageSrc = computed(() => `/images/logos/tw-logo-${props.variant}${props.withClaim ? "-claim" : ""}.png`);
</script>

<template>
  <img :alt="$t('header.logo_alt')" :src="imageSrc" :width="width" :height="height" />
</template>
